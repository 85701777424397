import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div className="p-3">
      {/* <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="left-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    {" "}
                    <img
                      src={require("../Assets/Images/dark-logo.png")}
                    />
                  </div>
                  <h2>Welcome back!</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                  <Form>
                    <div className="form-set">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email "
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 pass-eys"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          className="password-input"
                          type="password"
                          placeholder="Password"
                        />
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                        />
                      </Form.Group>
                    </div>
                    <div className="pass-rember-line">
                      <Link to="/forgot-password" className="forgot">
                        Forgot Password?
                      </Link>
                    </div>

                    <Link
                      to="/otp-varification"
                      variant="primary"
                      type="submit"
                      className="submit"
                    >
                      Log In
                    </Link>
                    {/* <div className="or-divider">
                      <p>or</p>
                    </div>
                    <div className="sign-social-links">
                      <button>
                        <img
                          src={
                            require("../Assets/Images/android-icon.svg").default
                          }
                        />
                        Continue with Google
                      </button>
                      <button>
                        {" "}
                        <img
                          src={require("../Assets/Images/ios-icon.svg").default}
                        />
                        Continue with Apple
                      </button>
                    </div> */}
                    {/* <div className="alreadyac-txt-line">
                      <p>
                        Don't have an account?{" "}
                        <Link to="/sign-up" className="login-link-txt">
                          Sign Up{" "}
                        </Link>
                      </p>
                    </div> */}
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
