import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Payments() {
  const Navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [secondShow, setSecondShow] = useState(false);
  const handleSecondClose = () => setSecondShow(false);
  const handleSecondShow = () => setSecondShow(true);

  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="comm-top-title-sec">
          <h2>Payments</h2>
          {/* <div className="home-welcome-txt">
            <button class="white-bg" onClick={handleSecondShow}>
              Make a song request
            </button>
            <button class="color-bg" onClick={() => Navigate("/all-songs")}>
              Add More
            </button>
          </div> */}
        </div>
        <Row>
          <Col lg={12}>
            <div className="filters mt-0">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>
                          <div className="user-checkbox">Date</div>
                        </th>
                        <th>Event Name </th>
                        <th>Amount </th>
                        <th>Status</th>
                        <th>Payment Method </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nov 15, 2024</td>
                        <td>Wedding Concert</td>
                        <td>£500</td>
                        <td>Pending</td>
                        <td>Stripe</td>
                        <td>
                          <div
                            className="td-txt-theme cursor-pointer"
                            onClick={handleSecondShow}
                          >
                            Pay Now
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Nov 15, 2024</td>
                        <td>Wedding Concert</td>
                        <td>£500</td>
                        <td>Pending</td>
                        <td>Stripe</td>
                        <td>
                          <div className="td-txt-bg">Download Receipt</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {/* <div className="no-data-found">
                    <button onClick={() => Navigate("/all-songs")}>
                      Add Songs
                    </button>
                  </div> */}
                  <div className="pagination-section">
                    <div className="showing-user">
                      <p>Showing 1 to 10 of 50 entries</p>
                    </div>
                    <div className="pagination-block">
                      <Pagination>
                        {/* <Pagination.First /> */}
                        <Pagination.Prev />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Next />
                        {/* <Pagination.Last /> */}
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Complete Your Payment!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <div className="">
              <p>
                Please use the details below to complete your payment via bank
                transfer. Ensure you include the reference number in your
                payment to help us identify your transaction.
              </p>

              <p className="mb-2">
                Payment: <b>£250</b>
              </p>
              <p className="mb-2">
                Due Date: <b>December 5th, 2024</b>
              </p>
              <p>
                Reference Number: <b>GN20241210</b>
              </p>
              <p>Bank Details:</p>
              <ul className="list-unstyled ps-3">
                <li>
                  <p className="mb-2">
                    Account Name: <b>Bianca Events Ltd.</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Sort Code: <b>12-34-56</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Account Number: <b>12345678</b>
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    Bank Name: <b>HSBC Bank</b>
                  </p>
                </li>
              </ul>
              <p>
                After completing your payment, please click the button below to
                notify us.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="primary w-100" onClick={handleClose}>
            I Made This Payment
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Complete Your Payment!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="">
            <p className="pop-small-txt">
              You have a pending payment of <b>£250</b> for your event,{" "}
              <b>Gala Night</b>.
            </p>
            <p>
              Please complete the payment by <b>December 5th</b>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary w-50" onClick={handleShow}>
            View Bank Transfer Details{" "}
          </button>
          <button className="primary w-40" onClick={handleSecondClose}>
            Pay Now
          </button>
        </Modal.Footer>
        <span className="please-note-txt">
          * Please note - Card payments will occur a 2% booking fee
        </span>
      </Modal>
    </Layout>
  );
}
