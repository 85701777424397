import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function About() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <Row>
          <Col lg={12}>
            <div className="about-box-sec">
              <div className="about-box-title">
                <p>Meet Your Musicians</p>
                <h2>Discover the passion and talent behind your event.</h2>
              </div>
              <Row className="about-sec-set">
                <Col lg={6}>
                  <div className="about-img-side">
                    <img src={require("../Assets/Images/about-one.png")} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="about-txt-side">
                    <h3>Bianca Blezard</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M22.2 10.875C20.9734 10.875 19.797 11.3623 18.9296 12.2296C18.0623 13.097 17.575 14.2734 17.575 15.5V18.075H15.1C14.9757 18.075 14.875 18.1757 14.875 18.3V21.7C14.875 21.8243 14.9757 21.925 15.1 21.925H17.575V28.9C17.575 29.0243 17.6757 29.125 17.8 29.125H21.2C21.3243 29.125 21.425 29.0243 21.425 28.9V21.925H23.9219C24.0252 21.925 24.1152 21.8547 24.1402 21.7546L24.9902 18.3546C25.0257 18.2126 24.9183 18.075 24.7719 18.075H21.425V15.5C21.425 15.2945 21.5067 15.0973 21.652 14.952C21.7973 14.8067 21.9945 14.725 22.2 14.725H24.8C24.9243 14.725 25.025 14.6243 25.025 14.5V11.1C25.025 10.9757 24.9243 10.875 24.8 10.875H22.2Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M20.0007 16.75C18.2057 16.75 16.7507 18.2051 16.7507 20C16.7507 21.795 18.2057 23.25 20.0007 23.25C21.7956 23.25 23.2507 21.795 23.2507 20C23.2507 18.2051 21.7956 16.75 20.0007 16.75Z"
                            fill="#464646"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.7702 11.0815C18.2183 10.6961 21.783 10.6961 25.2312 11.0815C27.1296 11.2937 28.6607 12.7895 28.8836 14.6945C29.2958 18.2195 29.2958 21.7806 28.8836 25.3056C28.6607 27.2106 27.1296 28.7064 25.2312 28.9186C21.783 29.304 18.2183 29.304 14.7702 28.9186C12.8718 28.7064 11.3406 27.2106 11.1178 25.3056C10.7055 21.7806 10.7055 18.2195 11.1178 14.6945C11.3406 12.7895 12.8718 11.2937 14.7702 11.0815ZM25.0007 14C24.4484 14 24.0007 14.4478 24.0007 15C24.0007 15.5523 24.4484 16 25.0007 16C25.553 16 26.0007 15.5523 26.0007 15C26.0007 14.4478 25.553 14 25.0007 14ZM15.2507 20C15.2507 17.3767 17.3773 15.25 20.0007 15.25C22.624 15.25 24.7507 17.3767 24.7507 20C24.7507 22.6234 22.624 24.75 20.0007 24.75C17.3773 24.75 15.2507 22.6234 15.2507 20Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.988 12.8905C18.3237 12.6296 21.6747 12.6296 25.0103 12.8905L27.251 13.0659C28.4991 13.1635 29.5201 14.0989 29.7264 15.3337C30.2426 18.4232 30.2426 21.5769 29.7264 24.6664C29.5201 25.9012 28.4991 26.8366 27.251 26.9342L25.0103 27.1095C21.6747 27.3705 18.3237 27.3705 14.988 27.1095L12.7473 26.9342C11.4993 26.8366 10.4782 25.9012 10.2719 24.6664C9.75571 21.5769 9.75571 18.4232 10.2719 15.3337C10.4782 14.0989 11.4993 13.1635 12.7473 13.0659L14.988 12.8905ZM17.9991 22.4702V17.5299C17.9991 17.2967 18.2535 17.1527 18.4535 17.2726L22.5704 19.7428C22.7646 19.8593 22.7646 20.1408 22.5704 20.2573L18.4535 22.7274C18.2535 22.8474 17.9991 22.7034 17.9991 22.4702Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row className="about-tow-sec-set">
                <Col lg={6}>
                  <div className="about-txt-side">
                    <h3>Estere</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M22.2 10.875C20.9734 10.875 19.797 11.3623 18.9296 12.2296C18.0623 13.097 17.575 14.2734 17.575 15.5V18.075H15.1C14.9757 18.075 14.875 18.1757 14.875 18.3V21.7C14.875 21.8243 14.9757 21.925 15.1 21.925H17.575V28.9C17.575 29.0243 17.6757 29.125 17.8 29.125H21.2C21.3243 29.125 21.425 29.0243 21.425 28.9V21.925H23.9219C24.0252 21.925 24.1152 21.8547 24.1402 21.7546L24.9902 18.3546C25.0257 18.2126 24.9183 18.075 24.7719 18.075H21.425V15.5C21.425 15.2945 21.5067 15.0973 21.652 14.952C21.7973 14.8067 21.9945 14.725 22.2 14.725H24.8C24.9243 14.725 25.025 14.6243 25.025 14.5V11.1C25.025 10.9757 24.9243 10.875 24.8 10.875H22.2Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            d="M20.0007 16.75C18.2057 16.75 16.7507 18.2051 16.7507 20C16.7507 21.795 18.2057 23.25 20.0007 23.25C21.7956 23.25 23.2507 21.795 23.2507 20C23.2507 18.2051 21.7956 16.75 20.0007 16.75Z"
                            fill="#464646"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.7702 11.0815C18.2183 10.6961 21.783 10.6961 25.2312 11.0815C27.1296 11.2937 28.6607 12.7895 28.8836 14.6945C29.2958 18.2195 29.2958 21.7806 28.8836 25.3056C28.6607 27.2106 27.1296 28.7064 25.2312 28.9186C21.783 29.304 18.2183 29.304 14.7702 28.9186C12.8718 28.7064 11.3406 27.2106 11.1178 25.3056C10.7055 21.7806 10.7055 18.2195 11.1178 14.6945C11.3406 12.7895 12.8718 11.2937 14.7702 11.0815ZM25.0007 14C24.4484 14 24.0007 14.4478 24.0007 15C24.0007 15.5523 24.4484 16 25.0007 16C25.553 16 26.0007 15.5523 26.0007 15C26.0007 14.4478 25.553 14 25.0007 14ZM15.2507 20C15.2507 17.3767 17.3773 15.25 20.0007 15.25C22.624 15.25 24.7507 17.3767 24.7507 20C24.7507 22.6234 22.624 24.75 20.0007 24.75C17.3773 24.75 15.2507 22.6234 15.2507 20Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.988 12.8905C18.3237 12.6296 21.6747 12.6296 25.0103 12.8905L27.251 13.0659C28.4991 13.1635 29.5201 14.0989 29.7264 15.3337C30.2426 18.4232 30.2426 21.5769 29.7264 24.6664C29.5201 25.9012 28.4991 26.8366 27.251 26.9342L25.0103 27.1095C21.6747 27.3705 18.3237 27.3705 14.988 27.1095L12.7473 26.9342C11.4993 26.8366 10.4782 25.9012 10.2719 24.6664C9.75571 21.5769 9.75571 18.4232 10.2719 15.3337C10.4782 14.0989 11.4993 13.1635 12.7473 13.0659L14.988 12.8905ZM17.9991 22.4702V17.5299C17.9991 17.2967 18.2535 17.1527 18.4535 17.2726L22.5704 19.7428C22.7646 19.8593 22.7646 20.1408 22.5704 20.2573L18.4535 22.7274C18.2535 22.8474 17.9991 22.7034 17.9991 22.4702Z"
                            fill="#464646"
                          />
                          <circle
                            opacity="0.65"
                            cx="20"
                            cy="20"
                            r="19.5"
                            fill="#979699"
                            fill-opacity="0.2"
                            stroke="#3B5A6A"
                          />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="about-img-side ">
                    <img src={require("../Assets/Images/about-two.png")} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
