import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function ClientsDetail() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="detail-view-card">
              <div className="detail-pro-img">
                <img src={require("../../src/Assets/Images/user.png")} />
              </div>
              <div className="detail-pro-name">
                <h2>Jordan Stevenson</h2>
                <p>example@gmail.com</p>
              </div>
              <div className="detail-pro-edition">
                <p>
                  Created Date: <span>Nov 30,2024</span>
                </p>
                <p>
                  Booked: <span>Yes</span>
                </p>
              </div>
              <div className="detail-pro-name">
                <h2>Jordan Stevenson</h2>
                <p>example@gmail.com</p>
              </div>
              <div className="detail-pro-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g opacity="0.8">
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="comn-table-title">
              <h3>
                Bookings <span>(12)</span>
              </h3>
              {/* <button onClick={handleShow}>Create Client</button> */}
            </div>
            <div className="filters">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>
                          <div className="user-checkbox">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            Date Of Booking
                          </div>
                        </th>
                        <th>Status/ Action</th>
                        <th>High Level Notes</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <div className="user-profile">Nov 30, 2024</div>
                          </div>
                        </td>
                        <td>Booked</td>
                        <td>
                          <div className="td-txt-bg">View</div>
                        </td>
                        <td>
                          <div className="td-txt-bg">View all details</div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="pagination-section">
                    <div className="showing-user">
                      <p>Showing 1 to 10 of 50 entries</p>
                    </div>
                    <div className="pagination-block">
                      <Pagination>
                        {/* <Pagination.First /> */}
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item active>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Next />
                        {/* <Pagination.Last /> */}
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="comn-table-title">
              <h3>Chat</h3>
              {/* <button onClick={handleShow}>Create Client</button> */}
            </div>
            <div className="message-box-chatscreen">
              <div className="chatscreen-top justify-content-center">
                <h3>Jordan Stevenson</h3>
              </div>
              <div className="chatscreen-middle">
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
              </div>
              <div className="chatscreen-bottom">
                <input type="text" placeholder="Write message here..." />
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_103_5849)">
                      <path
                        d="M0.143157 3.08995C-0.0154793 2.65963 -0.0411072 2.19157 0.0696001 1.74652C0.180307 1.30146 0.422238 0.899952 0.763991 0.594112C1.10298 0.287237 1.52615 0.0889965 1.97891 0.0249753C2.43166 -0.0390459 2.89321 0.0340903 3.30399 0.234945L18.379 7.27161C18.7697 7.45195 19.1167 7.71457 19.3965 8.0415C19.6762 8.36842 19.882 8.75193 19.9998 9.16578H3.37316L0.190657 3.19578C0.173134 3.16128 0.157283 3.12597 0.143157 3.08995ZM3.38482 10.8333L0.257324 16.8124C0.239945 16.8452 0.224908 16.8792 0.212324 16.9141C0.0543303 17.3445 0.0293644 17.8125 0.140667 18.2572C0.25197 18.702 0.494401 19.103 0.836491 19.4083C1.25758 19.7875 1.80399 19.9976 2.37066 19.9983C2.71149 19.9983 3.05316 19.9216 3.37149 19.7658L18.3807 12.7341C18.772 12.5532 19.1195 12.2898 19.3993 11.9618C19.6791 11.6338 19.8846 11.2491 20.0015 10.8341H3.38482V10.8333Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_103_5849">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Full Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email ID*</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email " />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Estimated Date</Form.Label>
                  <Form.Control type="date" placeholder="Select date" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
