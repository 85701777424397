import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default function Events() {
  const Navigate = useNavigate();

  const handleDateClick = (arg) => {
    alert(arg.dateStr);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            December 2024{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.0303 16.5303C14.7374 16.8232 14.2626 16.8232 13.9697 16.5303L9.96967 12.5303C9.67678 12.2374 9.67678 11.7626 9.96967 11.4697L13.9697 7.46967C14.2626 7.17678 14.7374 7.17678 15.0303 7.46967C15.3232 7.76256 15.3232 8.23744 15.0303 8.53033L11.5607 12L15.0303 15.4697C15.3232 15.7626 15.3232 16.2374 15.0303 16.5303Z"
                  fill="#132027"
                />
              </svg>
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.96967 16.5303C9.26256 16.8232 9.73744 16.8232 10.0303 16.5303L14.0303 12.5303C14.3232 12.2374 14.3232 11.7626 14.0303 11.4697L10.0303 7.46967C9.73744 7.17678 9.26256 7.17678 8.96967 7.46967C8.67678 7.76256 8.67678 8.23744 8.96967 8.53033L12.4393 12L8.96967 15.4697C8.67678 15.7626 8.67678 16.2374 8.96967 16.5303Z"
                  fill="#132027"
                />
              </svg>
            </span>
          </h3>
          <button onClick={handleShow}>Create Event</button>
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              weekends={false}
              dateClick={(e) => handleDateClick(e)}
              events={[
                { title: "event 1", date: "2021-05-07" },
                { title: "event 2", date: "2021-05-17" },
              ]}
              // eventContent={renderEventContent}
            />
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Client</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select Client</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" placeholder="Select date" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Time</Form.Label>
                  <div className="d-flex align-items-center gap-3">
                    <Form.Control type="time" placeholder="Enter your email " />
                    <p className="m-0">To</p>
                    <Form.Control type="time" placeholder="Enter your email " />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Event name</Form.Label>
                  <Form.Control type="text" placeholder="Enter event name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Location</Form.Label>
                  <Form.Control type="text" placeholder="Search location" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Musician</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select Musician</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Price</Form.Label>
                  <Form.Control type="text" placeholder="Enter price" />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            className="primary"
            onClick={() => Navigate("/events-details")}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
