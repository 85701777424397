import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Accordion from "react-bootstrap/Accordion";

export default function Resources() {
  const Navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [secondShow, setSecondShow] = useState(false);
  const handleSecondClose = () => setSecondShow(false);
  const handleSecondShow = () => setSecondShow(true);

  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="songlist-selection">
          <div className="resource-card-list">
            <div className="resource-card">
              <div>
                <span>
                  <svg
                    width="28"
                    height="36"
                    viewBox="0 0 28 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5014 7.47197L19.7217 1.05792C19.0006 0.37568 18.0567 0 17.064 0H4.15625C2.02388 0 0.289062 1.73482 0.289062 3.86719V32.1328C0.289062 34.2652 2.02388 36 4.15625 36H23.8438C25.9761 36 27.7109 34.2652 27.7109 32.1328V10.2812C27.7109 9.2231 27.2701 8.19914 26.5014 7.47197ZM24.4527 8.4375H19.2031C19.0093 8.4375 18.8516 8.27979 18.8516 8.08594V3.13847L24.4527 8.4375ZM23.8438 33.8906H4.15625C3.18699 33.8906 2.39844 33.1021 2.39844 32.1328V3.86719C2.39844 2.89793 3.18699 2.10938 4.15625 2.10938H16.7422V8.08594C16.7422 9.4429 17.8462 10.5469 19.2031 10.5469H25.6016V32.1328C25.6016 33.1021 24.813 33.8906 23.8438 33.8906Z"
                      fill="white"
                    />
                    <path
                      d="M21.5234 14.0625H6.05469C5.47222 14.0625 5 14.5347 5 15.1172C5 15.6997 5.47222 16.1719 6.05469 16.1719H21.5234C22.1059 16.1719 22.5781 15.6997 22.5781 15.1172C22.5781 14.5347 22.1059 14.0625 21.5234 14.0625ZM21.5234 19.6875H6.05469C5.47222 19.6875 5 20.1597 5 20.7422C5 21.3247 5.47222 21.7969 6.05469 21.7969H21.5234C22.1059 21.7969 22.5781 21.3247 22.5781 20.7422C22.5781 20.1597 22.1059 19.6875 21.5234 19.6875ZM11.1678 25.3125H6.05469C5.47222 25.3125 5 25.7847 5 26.3672C5 26.9497 5.47222 27.4219 6.05469 27.4219H11.1678C11.7503 27.4219 12.2225 26.9497 12.2225 26.3672C12.2225 25.7847 11.7503 25.3125 11.1678 25.3125Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <p>Resource 1</p>
              </div>
              <button>Download</button>
            </div>
            <div className="resource-card">
              <div>
                <span>
                  <svg
                    width="28"
                    height="36"
                    viewBox="0 0 28 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5014 7.47197L19.7217 1.05792C19.0006 0.37568 18.0567 0 17.064 0H4.15625C2.02388 0 0.289062 1.73482 0.289062 3.86719V32.1328C0.289062 34.2652 2.02388 36 4.15625 36H23.8438C25.9761 36 27.7109 34.2652 27.7109 32.1328V10.2812C27.7109 9.2231 27.2701 8.19914 26.5014 7.47197ZM24.4527 8.4375H19.2031C19.0093 8.4375 18.8516 8.27979 18.8516 8.08594V3.13847L24.4527 8.4375ZM23.8438 33.8906H4.15625C3.18699 33.8906 2.39844 33.1021 2.39844 32.1328V3.86719C2.39844 2.89793 3.18699 2.10938 4.15625 2.10938H16.7422V8.08594C16.7422 9.4429 17.8462 10.5469 19.2031 10.5469H25.6016V32.1328C25.6016 33.1021 24.813 33.8906 23.8438 33.8906Z"
                      fill="white"
                    />
                    <path
                      d="M21.5234 14.0625H6.05469C5.47222 14.0625 5 14.5347 5 15.1172C5 15.6997 5.47222 16.1719 6.05469 16.1719H21.5234C22.1059 16.1719 22.5781 15.6997 22.5781 15.1172C22.5781 14.5347 22.1059 14.0625 21.5234 14.0625ZM21.5234 19.6875H6.05469C5.47222 19.6875 5 20.1597 5 20.7422C5 21.3247 5.47222 21.7969 6.05469 21.7969H21.5234C22.1059 21.7969 22.5781 21.3247 22.5781 20.7422C22.5781 20.1597 22.1059 19.6875 21.5234 19.6875ZM11.1678 25.3125H6.05469C5.47222 25.3125 5 25.7847 5 26.3672C5 26.9497 5.47222 27.4219 6.05469 27.4219H11.1678C11.7503 27.4219 12.2225 26.9497 12.2225 26.3672C12.2225 25.7847 11.7503 25.3125 11.1678 25.3125Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <p>Resource 2</p>
              </div>
              <button>Download</button>
            </div>
            <div className="resource-card">
              <div>
                <span>
                  <svg
                    width="28"
                    height="36"
                    viewBox="0 0 28 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5014 7.47197L19.7217 1.05792C19.0006 0.37568 18.0567 0 17.064 0H4.15625C2.02388 0 0.289062 1.73482 0.289062 3.86719V32.1328C0.289062 34.2652 2.02388 36 4.15625 36H23.8438C25.9761 36 27.7109 34.2652 27.7109 32.1328V10.2812C27.7109 9.2231 27.2701 8.19914 26.5014 7.47197ZM24.4527 8.4375H19.2031C19.0093 8.4375 18.8516 8.27979 18.8516 8.08594V3.13847L24.4527 8.4375ZM23.8438 33.8906H4.15625C3.18699 33.8906 2.39844 33.1021 2.39844 32.1328V3.86719C2.39844 2.89793 3.18699 2.10938 4.15625 2.10938H16.7422V8.08594C16.7422 9.4429 17.8462 10.5469 19.2031 10.5469H25.6016V32.1328C25.6016 33.1021 24.813 33.8906 23.8438 33.8906Z"
                      fill="white"
                    />
                    <path
                      d="M21.5234 14.0625H6.05469C5.47222 14.0625 5 14.5347 5 15.1172C5 15.6997 5.47222 16.1719 6.05469 16.1719H21.5234C22.1059 16.1719 22.5781 15.6997 22.5781 15.1172C22.5781 14.5347 22.1059 14.0625 21.5234 14.0625ZM21.5234 19.6875H6.05469C5.47222 19.6875 5 20.1597 5 20.7422C5 21.3247 5.47222 21.7969 6.05469 21.7969H21.5234C22.1059 21.7969 22.5781 21.3247 22.5781 20.7422C22.5781 20.1597 22.1059 19.6875 21.5234 19.6875ZM11.1678 25.3125H6.05469C5.47222 25.3125 5 25.7847 5 26.3672C5 26.9497 5.47222 27.4219 6.05469 27.4219H11.1678C11.7503 27.4219 12.2225 26.9497 12.2225 26.3672C12.2225 25.7847 11.7503 25.3125 11.1678 25.3125Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <p>Resource 3</p>
              </div>
              <button>Download</button>
            </div>
            <div className="resource-card">
              <div>
                <span>
                  <svg
                    width="28"
                    height="36"
                    viewBox="0 0 28 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5014 7.47197L19.7217 1.05792C19.0006 0.37568 18.0567 0 17.064 0H4.15625C2.02388 0 0.289062 1.73482 0.289062 3.86719V32.1328C0.289062 34.2652 2.02388 36 4.15625 36H23.8438C25.9761 36 27.7109 34.2652 27.7109 32.1328V10.2812C27.7109 9.2231 27.2701 8.19914 26.5014 7.47197ZM24.4527 8.4375H19.2031C19.0093 8.4375 18.8516 8.27979 18.8516 8.08594V3.13847L24.4527 8.4375ZM23.8438 33.8906H4.15625C3.18699 33.8906 2.39844 33.1021 2.39844 32.1328V3.86719C2.39844 2.89793 3.18699 2.10938 4.15625 2.10938H16.7422V8.08594C16.7422 9.4429 17.8462 10.5469 19.2031 10.5469H25.6016V32.1328C25.6016 33.1021 24.813 33.8906 23.8438 33.8906Z"
                      fill="white"
                    />
                    <path
                      d="M21.5234 14.0625H6.05469C5.47222 14.0625 5 14.5347 5 15.1172C5 15.6997 5.47222 16.1719 6.05469 16.1719H21.5234C22.1059 16.1719 22.5781 15.6997 22.5781 15.1172C22.5781 14.5347 22.1059 14.0625 21.5234 14.0625ZM21.5234 19.6875H6.05469C5.47222 19.6875 5 20.1597 5 20.7422C5 21.3247 5.47222 21.7969 6.05469 21.7969H21.5234C22.1059 21.7969 22.5781 21.3247 22.5781 20.7422C22.5781 20.1597 22.1059 19.6875 21.5234 19.6875ZM11.1678 25.3125H6.05469C5.47222 25.3125 5 25.7847 5 26.3672C5 26.9497 5.47222 27.4219 6.05469 27.4219H11.1678C11.7503 27.4219 12.2225 26.9497 12.2225 26.3672C12.2225 25.7847 11.7503 25.3125 11.1678 25.3125Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <p>Resource 4</p>
              </div>
              <button>Download</button>
            </div>
          </div>
        </div>
        <div className="songlist-selection mt-4">
          <div className="my-profile-title">
            <h4>FAQs</h4>
          </div>
          <div className="faq-list-accro">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Lorem ipsum dolor sit amet, consectetur adipiscing?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Artist</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Youtube or Spotify URL (Optional)</Form.Label>
                  <Form.Control type="text" placeholder="https://" />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
