import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  return (
    <div className="p-3">
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="otp-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    {" "}
                    <img
                      src={require("../Assets/Images/dark-logo.png")}
                    />
                  </div>
                  <h2>Forgot password</h2>
                  <p className="mb-0">Please enter your registered email</p>
                  <Form>
                    <div className="form-set">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your Registered Email"
                        />
                      </Form.Group>
                    </div>

                    <Link
                      to="/reset-password"
                      variant="primary"
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Submit
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
