import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

export default function SignUp() {
  return (
    <div className="p-3">
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="otp-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    {" "}
                    <img
                      src={require("../Assets/Images/dark-logo.png")}
                    />
                  </div>
                  <h2>Join us today</h2>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur
                  </p>
                  <Form>
                    <div className="form-set">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your Full Name"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your Registered Email"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 pass-eys"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          className="password-input"
                          type="password"
                          placeholder="Enter your Password"
                        />
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 pass-eys"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          className="password-input"
                          type="password"
                          placeholder="Re-Enter your Password"
                        />
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                        />
                      </Form.Group>
                    </div>
                    <div className="remember-check">
                      <input type="checkbox" class="red" id="filled-in-box" />
                      <Form.Label>
                        I have read and agreed to the{" "}
                        <Link href="" className="login-link-txt">
                          Terms and Conditions of Service
                        </Link>
                      </Form.Label>
                    </div>
                    <Link
                      to="/reset-password"
                      variant="primary"
                      type="submit"
                      className="submit forgot-btn"
                    >
                      Sign Up
                    </Link>
                    <div className="alreadyac-txt-line">
                      <p>
                        Already have an account? <Link to="/" className="login-link-txt">
                          Log In
                        </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
