import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import OtpVarification from "./Pages/OtpVarification";
import SignUp from "./Pages/SignUp";
import Clients from "./Pages/Clients";
import ForgotPassword from "./Pages/ForgotPassword";
import ClientsDetail from "./Pages/ClientsDetail";
import Events from "./Pages/Events";
import EventsDetails from "./Pages/EventsDetails";
import Transactions from "./Pages/Transactions";
import Songs from "./Pages/Songs";
import Administrators from "./Pages/Administrators";
import Home from "./Pages/Home";
import About from "./Pages/About";
import SongList from "./Pages/SongList";
import AllSongs from "./Pages/AllSongs";
import Resources from "./Pages/Resources";
import Messages from "./Pages/Messages";
import Payments from "./Pages/Payments";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/sign-up" element={<SignUp />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/error" element={<Error />}></Route>
          <Route path="/otp-varification" element={<OtpVarification />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/song-list" element={<SongList />}></Route>
          <Route path="/all-songs" element={<AllSongs />}></Route>
          <Route path="/resources" element={<Resources />}></Route>
          <Route path="/messages" element={<Messages />}></Route>
          <Route path="/payments" element={<Payments />}></Route>

          <Route path="/clients" element={<Clients />}></Route>
          <Route path="/client-detail" element={<ClientsDetail />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/events-details" element={<EventsDetails />}></Route>
          <Route path="/transactions" element={<Transactions />}></Route>
          <Route path="/songs" element={<Songs />}></Route>
          <Route path="/administrators" element={<Administrators />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
