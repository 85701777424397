import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function Messages() {
  const Navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [secondShow, setSecondShow] = useState(false);
  const handleSecondClose = () => setSecondShow(false);
  const handleSecondShow = () => setSecondShow(true);

  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="comm-top-title-sec">
          <h2>Messages</h2>
          {/* <div className="home-welcome-txt">
            <button class="white-bg" onClick={handleSecondShow}>
              Make a song request
            </button>
            <button class="color-bg" onClick={() => Navigate("/all-songs")}>
              Add More
            </button>
          </div> */}
        </div>
        <Row className="">
          <Col lg={4}>
            <div className="filters">
              <div className="inner-filter-field">
                <div className="messages-list">
                  {/* <div className="messages-search">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                        stroke="#9F9EA1"
                        stroke-width="1.33333"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.9996 14.0001L11.0996 11.1001"
                        stroke="#9F9EA1"
                        stroke-width="1.33333"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <input type="text" placeholder="Search clients…" />
                  </div> */}
                  <div>
                    <div className="message-box-chatlist">
                      <ul>
                        <li>
                          <img
                            src={require("../Assets/Images/about-one.png")}
                          />
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                              <span className="msg-num">1</span>
                            </div>
                          </div>
                        </li>

                        {/* <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                              <span className="msg-num">1</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                              <span className="msg-num">1</span>
                            </div>
                          </div>
                        </li>

                        <li className="active">
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                              <span className="msg-num">1</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h2>Wedding at Park</h2>
                              <p>Donec molestie maximus volutpat....</p>
                            </div>
                            <div>
                              <span>3:45 PM</span>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="message-box-chatscreen">
              <div className="chatscreen-top justify-content-between">
                <h3>Bianca Blezard</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <g opacity="0.8">
                    <path
                      d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5C11 13.0523 11.4477 13.5 12 13.5Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 6.5C12.5523 6.5 13 6.05228 13 5.5C13 4.94772 12.5523 4.5 12 4.5C11.4477 4.5 11 4.94772 11 5.5C11 6.05228 11.4477 6.5 12 6.5Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 20.5C12.5523 20.5 13 20.0523 13 19.5C13 18.9477 12.5523 18.5 12 18.5C11.4477 18.5 11 18.9477 11 19.5C11 20.0523 11.4477 20.5 12 20.5Z"
                      stroke="#132027"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="chatscreen-middle">
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-left-side">
                  <p>
                    Quisque eu erat ac dolor faucibus hendrerit sed a nulla.
                  </p>
                  <span>3:02 PM</span>
                </div>
                <div className="chat-right-side">
                  <p>
                    Mauris condimentum diam turpis, sit amet dapibus ligula
                    faucibus ac.
                  </p>
                  <span>3:02 PM</span>
                </div>
              </div>
              <div className="chatscreen-bottom message-box-input">
                <input type="text" placeholder="Write message here..." />
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_103_5849)">
                      <path
                        d="M0.143157 3.08995C-0.0154793 2.65963 -0.0411072 2.19157 0.0696001 1.74652C0.180307 1.30146 0.422238 0.899952 0.763991 0.594112C1.10298 0.287237 1.52615 0.0889965 1.97891 0.0249753C2.43166 -0.0390459 2.89321 0.0340903 3.30399 0.234945L18.379 7.27161C18.7697 7.45195 19.1167 7.71457 19.3965 8.0415C19.6762 8.36842 19.882 8.75193 19.9998 9.16578H3.37316L0.190657 3.19578C0.173134 3.16128 0.157283 3.12597 0.143157 3.08995ZM3.38482 10.8333L0.257324 16.8124C0.239945 16.8452 0.224908 16.8792 0.212324 16.9141C0.0543303 17.3445 0.0293644 17.8125 0.140667 18.2572C0.25197 18.702 0.494401 19.103 0.836491 19.4083C1.25758 19.7875 1.80399 19.9976 2.37066 19.9983C2.71149 19.9983 3.05316 19.9216 3.37149 19.7658L18.3807 12.7341C18.772 12.5532 19.1195 12.2898 19.3993 11.9618C19.6791 11.6338 19.8846 11.2491 20.0015 10.8341H3.38482V10.8333Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_103_5849">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Artist</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Youtube or Spotify URL (Optional)</Form.Label>
                  <Form.Control type="text" placeholder="https://" />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
